/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface ThreadState {
  current: any | null;
  selected: number | null;
  items: Array<any>;
}

// Define the initial state using that type
const initialState: ThreadState = {
  current: null,
  selected: null,
  items: [],
};

const threadSlice = createSlice({
  name: 'thread',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentThread: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setSelectedThread: (state, action: PayloadAction<number | null>) => {
      state.selected = action.payload;
    },
    clearSelectedThread: state => {
      state.selected = null;
    },
    setThreadItems: (state, action: PayloadAction<Array<any>>) => {
      state.items = action.payload;
    },
  },
});

const {
  setCurrentThread,
  setSelectedThread,
  setThreadItems,
  clearSelectedThread,
} = threadSlice.actions;
const threadReducer = threadSlice.reducer;

export {
  ThreadState,
  threadReducer,
  setCurrentThread,
  setSelectedThread,
  setThreadItems,
  clearSelectedThread,
};
