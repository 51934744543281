import { property } from 'lit/decorators.js';
import { LitElement, css, html } from 'lit';
import { connect, store } from '../../../state/store';

import { Notification } from '@vaadin/notification';

import '@mch/nn-web-viz/dist/nn-star-rating';

import { xmark } from '../../../assets/icons/xmark';

import { monday, mondayQueries } from '../../../modules/monday/data';

class AdeleHelpOverlay extends connect(store)(LitElement) {
  @property({ type: String, attribute: 'question' }) question = '';
  @property({ type: String, attribute: 'answer' }) answer = '';
  @property({ type: Number, attribute: 'thread-id' }) threadId = 0;
  @property({ type: Boolean }) _sendingFeedback = false;
  @property({ type: Object }) user;

  static styles = css`
    :host {
      width: 800px;
      height: auto;
      background: rgba(28, 31, 40, 0.28);
      box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(18px);
      color: #fff;
      box-sizing: border-box;
      border-radius: 15px;
      margin-left: 10%;
    }

    .header-actions {
      display: flex;
      justify-content: flex-end;
      padding: 2rem 2rem 0 0;
    }

    nn-icon {
      cursor: pointer;
    }

    vaadin-text-field {
      color: white;
    }

    .content {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 1rem 3rem 3rem;
    }

    /* Button */
    .search-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 10px;
      margin-top: 4rem;
    }

    .search {
      margin: auto;
      width: 100%;
      border-radius: 30px;
      border: 0;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-size: 24px;
    }

    .search__input {
      outline: transparent;
      border: 0;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    .search__input {
      border-radius: 8px;
      background: #ffffff20;
      padding-left: 15px;
      padding-right: 150px;
      width: 100%;
      height: 156px;
      font-size: 1.2rem;
      color: #fff;
      resize: none;
    }

    .search__input::placeholder {
      color: #fff;
      opacity: 60%;
    }

    .search__input-wrap {
      position: relative;
    }

    .search__label {
      position: absolute;
      width: 1px;
      height: 1px;
    }

    .search__send {
      display: inline-block;
      cursor: pointer;
    }

    .search__actions {
      justify-content: space-between;
      align-items: end;
      width: 30px;
      display: flex;
      position: absolute;
      right: 20px;
      bottom: 10px;
      height: 100%;
      background-color: transparent;
      -webkit-tap-highlight-color: transparent;
    }

    .search button {
      background-color: transparent;
      border: 0;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    .search__input-wrap {
      display: flex;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      background-clip: padding-box;
      z-index: 1;
      border: solid 5px transparent;
      border-radius: 8px;
      width: 100%;
      height: auto;
    }

    h3 {
      margin: 0 0 0.5rem;
    }

    .input__label {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      margin-bottom: 1rem;
    }

    .submit__wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .submit__btn {
      margin-top: 2rem;
    }

    nn-star-rating {
      margin-bottom: 40px;
    }
    a {
      color: #fff;
    }
  `;

  stateChanged(_state) {
    this.user = _state.user.value;
  }

  _closeOverlay() {
    this.dispatchEvent(new Event('close-overlay', { bubbles: true }));
  }

  _handleSubmit(e) {
    e.preventDefault();

    const inputField = this.shadowRoot?.getElementById(
      'search'
    ) as HTMLInputElement;
    inputField.value = '';
  }

  _getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  async _submitHelpRequest() {
    const inputField = this.shadowRoot?.getElementById(
      'search'
    ) as HTMLInputElement;
    const helpRequestText = inputField.value;
    const userName = this.user.nickname || 'Username'; // Replace with actual user name retrieval logic

    this._sendingFeedback = true;
    try {
      const result = await monday.query(
        mondayQueries.submitHelpRequest({
          text: helpRequestText.replace(/(\r\n|\n|\r)/gm, ''),
          userName: userName,
          date: this._getCurrentDate(),
        })
      );

      if (result?.create_item) {
        inputField.value = '';
        Notification.show('Your help request has been submitted.', {
          position: 'bottom-end',
          duration: 2000,
          theme: 'success',
        });
        this._closeOverlay();
      } else {
        Notification.show('Failed to create item.', {
          position: 'bottom-end',
          duration: 5000,
          theme: 'error',
        });
      }
    } catch (error) {
      console.error(error);
      Notification.show('Unable to submit help request.', {
        position: 'bottom-end',
        duration: 5000,
        theme: 'error',
      });
    }
    this._sendingFeedback = false;
  }

  _renderSearchInput() {
    return html`<form class="search" @submit=${e => this._handleSubmit(e)}>
      <div>
        <h3>Need Help?</h3>
        <p>
          If you need assistance, you can email us at
          <a href="mailto:help@90north.health">help@90north.health</a> or fill
          out the form below:
        </p>
      </div>
      <div class="input__label">Describe your issue or request</div>

      <div class="search__input-wrap">
        <textarea
          class="search__input"
          id="search"
          type="text"
          name="search"
          placeholder="Type in here..."
        ></textarea>
      </div>
      <div class="submit__wrapper">
        <nn-button
          class="submit__btn"
          ghost
          @click=${this._submitHelpRequest}
          ?disabled=${this._sendingFeedback}
          >Submit Help Request</nn-button
        >
      </div>
    </form>`;
  }

  render() {
    return html`<div class="wrapper">
      <div class="header-actions">
        <nn-icon @click=${this._closeOverlay} .svg=${xmark}></nn-icon>
      </div>
      <div class="content">${this._renderSearchInput()}</div>
    </div>`;
  }
}

export { AdeleHelpOverlay };
