import { Page } from './enums/page';
import { getDeviceType } from './device-type';
import { getOS } from './os';
import { Analytics } from './types/analytics';
import { getViewport } from './viewport';
import {
  getTimeOnPage,
  resetTimeTracker,
  startTimeTracker,
} from './time-on-page';
import { getEvents, resetEventTracker } from './events';
import { User } from './types/user';
import { Account } from './types/account';

let _trackingPageView = false;
let _currentPage: Page | null = null;
let _previousPage: Page | null = null;
let _currentAccount: Account | null = null;
let _currentUser: User | null = null;

/**
 * Reset page event.
 * @private
 */
const _reset = () => {
  resetTimeTracker();
  resetEventTracker();

  _trackingPageView = false;
  _currentPage = null;
  _previousPage = null;
};

const createAnalytics = (): Analytics => ({
  user: _currentUser || { id: 0, name: 'Error fetching user' },
  account: _currentAccount || { id: 0, name: 'Error fetching account' },
  device: getDeviceType() || 'Unknown Device',
  os: getOS() || 'Unknown OS',
  viewport: getViewport() || 'Unknown viewport',
  referrer: document.referrer,
  page: _currentPage || Page.HOME,
  timeOnPage: getTimeOnPage(),
  events: getEvents(),
});

/**
 * Track currently logged-in user.
 * @public
 * @param user Currently logged-in user.
 */
const trackUser = (user: User) => {
  const { id, name } = user;

  _currentUser = { id, name };
};

/**
 * Track currently logged in account (e.g. 90North).
 * @public
 * @param account Currently logged in account.
 */
const trackAccount = (account: Account) => {
  _currentAccount = account;
};

/**
 * Track current page view.
 * This function will detect when there is a change in page and will send the analytics to the server.
 * @param param Current page.
 */
const trackPageView = ({ page }: { page: Page }) => {
  _trackingPageView = true;

  if (_currentPage !== page) {
    /**
     * If the current page is not the same as the new page, then we need to send the analytics to the server.
     */
    if (_trackingPageView && _currentPage != null) {
      const analytics: Analytics = createAnalytics();

      // SENDING OF ANALYTICS TO SERVER SHOULD BE MADE HERE
      // eslint-disable-next-line no-console
      console.log('Sending analytics to server: ', analytics);

      _reset();
    }

    startTimeTracker();

    /**
     * Set the current page to the new page.
     */
    _currentPage = page;
  }
};

/**
 * End tracking. This function will log the final events of the user.
 * @returns void
 */
const endTracking = () => {
  if (_currentPage == null) return;

  const analytics: Analytics = createAnalytics();

  // SENDING OF ANALYTICS TO SERVER SHOULD BE MADE HERE
  // eslint-disable-next-line no-console
  console.log('Sending analytics to server: ', analytics);

  _reset();
};

/**
 * Set previous page.
 * Specifically for tracking the page where the new thread popup was triggered.
 */
const setPreviousPage = () => {
  _previousPage = _currentPage;
};

/**
 * Track previous page.
 * Track page before the new thread popup was triggered.
 */
const trackPreviousPage = () => {
  trackPageView({ page: _previousPage || Page.HOME });
};

export {
  endTracking,
  trackPreviousPage,
  setPreviousPage,
  trackPageView,
  trackAccount,
  trackUser,
};
