{
    "name": "@adele-app/spa",
    "description": "ADELe front-end app",
    "author": "Tyler Doyle",
    "version": "0.146.1",
    "scripts": {
        "lint": "eslint --ext .ts,.html . --ignore-path .gitignore && prettier \"**/*.ts\" --check --ignore-path .gitignore",
        "format": "eslint --ext .ts,.html . --fix --ignore-path .gitignore && prettier \"**/*.ts\" --write --ignore-path .gitignore",
        "test": "run-s build:tsc test:wtr",
        "test:wtr": "wtr --coverage",
        "test:watch": "tsc && concurrently -k -r \"tsc --watch --preserveWatchOutput\" \"wtr --watch\"",
        "start:build": "web-dev-server --root-dir dist --app-index index.html --open",
        "analyze": "cem analyze --litelement",
        "dev:parcel": "parcel serve index.html --open --no-cache",
        "dev:tsc": "tsc --watch",
        "start": "./start.sh",
        "start-dev": "concurrently \"npm run dev:tsc\" \"npm run dev:parcel\"",
        "build": "rimraf dist && tsc && rollup -c rollup.config.js && npm run analyze -- --exclude dist",
        "build:tsc": "rimraf out-tsc && tsc",
        "build-lib": "run-s build-lib:clean build:tsc build-lib:bundle",
        "build-lib:clean": "rimraf lib",
        "build-lib:bundle": "parcel build"
    },
    "dependencies": {
        "@auth0/auth0-spa-js": "^2.1.3",
        "@azure/msal-browser": "^3.23.0",
        "@bundled-es-modules/axios": "^0.27.2",
        "@cubejs-client/core": "^0.32.30",
        "@mch/nn-web-viz": "^0.452.0",
        "@reduxjs/toolkit": "^1.9.7",
        "@vaadin/accordion": "^24.3.6",
        "@vaadin/app-layout": "^24.3.6",
        "@vaadin/button": "^24.3.6",
        "@vaadin/checkbox": "^24.3.6",
        "@vaadin/combo-box": "^24.3.6",
        "@vaadin/date-picker": "^24.3.6",
        "@vaadin/grid": "^24.3.6",
        "@vaadin/horizontal-layout": "^24.3.12",
        "@vaadin/icon": "^24.3.6",
        "@vaadin/icons": "^24.3.6",
        "@vaadin/multi-select-combo-box": "^24.3.6",
        "@vaadin/notification": "^24.3.6",
        "@vaadin/select": "^24.3.13",
        "@vaadin/side-nav": "^24.3.6",
        "@vaadin/tabs": "^24.3.6",
        "@vaadin/tabsheet": "^24.3.6",
        "@vaadin/text-field": "24.3.10",
        "@vaadin/tooltip": "^24.3.6",
        "@vaadin/upload": "^24.3.13",
        "@vaadin/vaadin-themable-mixin": "^24.3.6",
        "bignumber.js": "^9.1.2",
        "driver.js": "^1.3.1",
        "gridstack": "^9.5.1",
        "lit": "^2.8.0",
        "lit-html": "^3.2.1",
        "moment": "^2.30.1",
        "pwa-helpers": "^0.9.1",
        "react-redux": "^8.1.3",
        "rollup-plugin-dotenv": "^0.5.1",
        "uuid": "^9.0.1"
    },
    "devDependencies": {
        "@babel/preset-env": "^7.23.9",
        "@custom-elements-manifest/analyzer": "^0.4.17",
        "@open-wc/building-rollup": "^2.2.3",
        "@open-wc/eslint-config": "^4.3.0",
        "@open-wc/testing": "next",
        "@parcel/packager-raw-url": "^2.11.0",
        "@parcel/transformer-webmanifest": "^2.11.0",
        "@rollup/plugin-babel": "^5.3.1",
        "@rollup/plugin-commonjs": "^24.1.0",
        "@rollup/plugin-node-resolve": "^13.3.0",
        "@typescript-eslint/eslint-plugin": "^5.62.0",
        "@typescript-eslint/parser": "^5.62.0",
        "@web/dev-server": "^0.1.38",
        "@web/rollup-plugin-html": "^1.11.1",
        "@web/rollup-plugin-import-meta-assets": "^1.0.8",
        "@web/test-runner": "next",
        "babel-plugin-template-html-minifier": "^4.1.0",
        "concurrently": "^5.3.0",
        "deepmerge": "^4.3.1",
        "eslint": "^7.32.0",
        "eslint-config-prettier": "^8.10.0",
        "eslint-plugin-storybook": "^0.6.14",
        "husky": "^4.3.8",
        "lint-staged": "^10.5.4",
        "npm-run-all": "^4.1.5",
        "parcel": "^2.11.0",
        "path-browserify": "^1.0.1",
        "prettier": "^2.8.8",
        "process": "^0.11.10",
        "rimraf": "^3.0.2",
        "rollup": "^2.79.1",
        "rollup-plugin-copy": "^3.5.0",
        "rollup-plugin-postcss": "^4.0.2",
        "rollup-plugin-terser": "^7.0.2",
        "rollup-plugin-workbox": "^6.2.2",
        "tslib": "^2.6.2",
        "typescript": "^4.9.5"
    },
    "eslintConfig": {
        "parser": "@typescript-eslint/parser",
        "extends": [
            "@open-wc",
            "prettier"
        ],
        "plugins": [
            "@typescript-eslint"
        ],
        "rules": {
            "no-unused-vars": "off",
            "@typescript-eslint/no-unused-vars": [
                "error"
            ],
            "import/no-unresolved": "off",
            "import/extensions": [
                "error",
                "always",
                {
                    "ignorePackages": true
                }
            ]
        }
    },
    "prettier": {
        "singleQuote": true,
        "arrowParens": "avoid"
    },
    "customElements": "custom-elements.json",
    "targets": {
        "lib": {
            "source": "./lib.ts",
            "distDir": "./lib"
        }
    }
}
