let _currentTimeOnPage: number = 0;

let timer: any;

const startTimeTracker = (): void => {
  /**
   * Start the timer to track the time spent on the page.
   */
  timer = setInterval(() => {
    _currentTimeOnPage += 1;
  }, 1000);
};

const resetTimeTracker = () => {
  clearInterval(timer);
  _currentTimeOnPage = 0;
};

const getTimeOnPage = (): number => _currentTimeOnPage;

export { getTimeOnPage, resetTimeTracker, startTimeTracker };
