import { property } from 'lit/decorators';
import { CSSResult, LitElement, html, nothing } from 'lit';

import '@mch/nn-web-viz/dist/nn-icon';

import { navigate } from '@mch/nn-web-viz/dist/packages/router';
import {
  sendEvent,
  trackPreviousPage,
} from '@mch/nn-web-viz/dist/packages/analytics';
import { connect, store } from '../../../state/store';
import { setUserQuery } from '../../../state/slices/userQuery';
import { AdeleNewThreadOverlayStyles } from './AdeleNewThreadOverlayStyles';
import { clearSelectedThread } from '../../../state/slices/thread';
import { routePrefix } from '../../../utils';

import '../../adele-source-agents';
import '../../adele-search-input';

interface User {
  id: number;
  name: string;
}

const renderMagnifierIcon = () => html` <button
  class="search__button"
  type="submit"
  aria-label="Search"
>
  <svg
    class="search__icon"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M29.323 4.514a.243.243 0 0 1 0-.461l1.301-.434a1.942 1.942 0 0 0 1.229-1.228l.433-1.301a.243.243 0 0 1 .461 0l.434 1.301A1.942 1.942 0 0 0 34.41 3.62l1.3.434a.242.242 0 0 1 0 .46l-1.301.434a1.942 1.942 0 0 0-1.228 1.228l-.434 1.302a.243.243 0 0 1-.46 0l-.434-1.302a1.943 1.943 0 0 0-1.229-1.228l-1.301-.433Zm-3.427 2.595a.146.146 0 0 1 0-.277l.781-.26c.348-.116.621-.389.737-.737l.26-.78a.146.146 0 0 1 .277 0l.26.78a1.166 1.166 0 0 0 .737.737l.781.26a.145.145 0 0 1 .073.224.145.145 0 0 1-.073.053l-.78.26a1.166 1.166 0 0 0-.738.737l-.26.781a.146.146 0 0 1-.277 0l-.26-.78a1.163 1.163 0 0 0-.737-.738l-.78-.26Zm-.704-4.75a.098.098 0 0 1-.048-.148.098.098 0 0 1 .048-.035l.52-.174a.776.776 0 0 0 .491-.492l.174-.52a.098.098 0 0 1 .148-.047.098.098 0 0 1 .036.047l.173.52a.776.776 0 0 0 .492.492l.52.174a.098.098 0 0 1 .066.092.098.098 0 0 1-.066.092l-.52.173a.777.777 0 0 0-.492.492l-.173.52a.098.098 0 0 1-.149.047.098.098 0 0 1-.035-.048l-.174-.52a.776.776 0 0 0-.491-.492l-.52-.172Zm6.437 29.677-7.19-7.433A14.041 14.041 0 0 0 27.1 16.34c0-7.611-5.99-13.804-13.351-13.804C6.388 2.536.398 8.73.398 16.34c0 7.612 5.99 13.804 13.352 13.804a12.997 12.997 0 0 0 7.991-2.752l7.19 7.433c.364.337.839.516 1.327.502.488-.014.952-.22 1.297-.578.346-.356.545-.836.56-1.34a2.015 2.015 0 0 0-.486-1.373ZM4.213 16.34c0-1.95.56-3.856 1.607-5.478a9.613 9.613 0 0 1 4.28-3.631 9.246 9.246 0 0 1 5.51-.561c1.85.38 3.55 1.32 4.883 2.698a9.968 9.968 0 0 1 2.61 5.049 10.17 10.17 0 0 1-.542 5.697 9.796 9.796 0 0 1-3.513 4.425A9.315 9.315 0 0 1 13.75 26.2c-2.528-.003-4.952-1.043-6.74-2.891-1.788-1.849-2.794-4.355-2.797-6.969Z"
      fill="#fff"
    ></path>
  </svg>
</button>`;

const renderSendSearchActions = () => html`
  <div class="search__actions">
    <button class="search__send">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="39"
        height="38"
        viewBox="0 0 39 38"
        fill="none"
      >
        <rect
          x="0.636719"
          y="0.211914"
          width="37.9657"
          height="37.4233"
          rx="10.0885"
          fill="rgba(255, 255, 255, 0.53);"
        ></rect>
        <path
          d="M18.449 18.9245L13.9149 18.9245M28.467 18.4099C28.5634 18.4568 28.6447 18.53 28.7016 18.621C28.7584 18.7119 28.7885 18.8171 28.7884 18.9244C28.7883 19.0316 28.7581 19.1367 28.7011 19.2276C28.6441 19.3185 28.5627 19.3915 28.4662 19.4384L11.196 27.7553C11.0927 27.805 10.9769 27.8225 10.8636 27.8056C10.7502 27.7887 10.6446 27.7381 10.5604 27.6603C10.4761 27.5826 10.4172 27.4814 10.3912 27.3698C10.3652 27.2582 10.3734 27.1413 10.4146 27.0344L13.4547 19.1293C13.5054 18.9975 13.5054 18.8515 13.4547 18.7197L10.4154 10.8138C10.3745 10.707 10.3666 10.5904 10.3927 10.4791C10.4187 10.3677 10.4776 10.2668 10.5617 10.1892C10.6457 10.1117 10.7511 10.0612 10.8641 10.0441C10.9772 10.0271 11.0928 10.0443 11.196 10.0937L28.467 18.4099Z"
          stroke="white"
          stroke-width="3.39973"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </button>
  </div>
`;

class AdeleNewThreadOverlay extends connect(store)(LitElement) {
  @property({ type: Boolean }) _showDropDown = false;

  /**
   * @private
   * Flag to show the sources popup.
   */
  @property({ type: Boolean }) _showSourcesPopup = false;

  /**
   * @private
   * Flag to show the sources popup.
   */
  @property({ type: Boolean }) _showAgentsPopup = false;

  @property({ type: Number }) _selectedSource: number | null = null;

  @property({ type: Number }) _selectedAgent: number | null = null;

  @property({ type: Object }) _currentAccount: User | null = null;

  @property({ type: String }) _searchQuery: string = '';

  static styles: CSSResult[] = [AdeleNewThreadOverlayStyles];

  connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener('click', this._clickWatcher);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.removeEventListener('click', this._clickWatcher);
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('_selectedSource')) {
      const sourcesValue = this.shadowRoot?.getElementById('sourcesValue');
      const sourcesButton = this.shadowRoot?.getElementById('sourcesButton');

      if (sourcesValue == null || sourcesButton == null) return;

      const hasSelectedSource =
        this._selectedSource != null && this._selectedSource !== -1;

      sourcesValue.style.display = hasSelectedSource ? 'flex' : 'none';
      sourcesButton.style.borderColor = hasSelectedSource
        ? '#40ffd7'
        : 'transparent';
    }

    if (changedProps.has('_selectedAgent')) {
      const agentsValue = this.shadowRoot?.getElementById('agentsValue');
      const agentsButton = this.shadowRoot?.getElementById('agentsButton');

      if (agentsValue == null || agentsButton == null) return;

      const hasSelecteAgent =
        this._selectedAgent != null && this._selectedAgent !== -1;

      agentsValue.style.display = hasSelecteAgent ? 'flex' : 'none';
      agentsButton.style.borderColor = hasSelecteAgent
        ? '#40ffd7'
        : 'transparent';
    }
  }

  stateChanged(_state) {
    if (this._currentAccount?.id !== _state.appConfig.currentAccount?.id) {
      this._currentAccount = _state.appConfig.currentAccount;
    }

    const selectedSourceChanged =
      this._selectedSource !== _state.appConfig.selectedSource;
    const selectedAgentChanged =
      this._selectedAgent !== _state.appConfig.selectedAgent;

    if (selectedSourceChanged && _state.appConfig.selectedSource !== null) {
      this._selectedSource = _state.appConfig.selectedSource;
    }

    if (selectedAgentChanged && _state.appConfig.selectedAgent !== null) {
      this._selectedAgent = _state.appConfig.selectedAgent;
    }
  }

  _clickWatcher(e) {
    const composedPath = e.composedPath();

    if (
      !composedPath.includes(this.shadowRoot?.querySelector('form.search')) &&
      !composedPath.includes(
        this.shadowRoot?.querySelector('adele-source-agents')
      )
    ) {
      this._closeOverlay();
    }
  }

  _closeOverlay() {
    trackPreviousPage();

    this.dispatchEvent(new Event('close-overlay', { bubbles: true }));

    const editableElement: any =
      this.shadowRoot?.querySelector('.search__input');
    if (editableElement != null) {
      editableElement.clear();
      editableElement.focus();
    }
  }

  _closeAndRedirectToHome() {
    navigate(`${routePrefix}/home`);
    this.dispatchEvent(new Event('close-overlay', { bubbles: true }));
  }

  _handleSubmit(e) {
    e?.preventDefault();

    sendEvent(e);

    this._performSearch();
  }

  _performSearch() {
    if (this._searchQuery === '' || this._searchQuery == null) return;

    store.dispatch(clearSelectedThread());
    store.dispatch(setUserQuery(this._searchQuery as any));

    this._closeAndRedirectToHome();
  }

  _watchForEnterKey(e) {
    if (e.keyCode === 13) {
      this._handleSubmit(e);
    }
  }

  _toggleSourceDropdown(e) {
    sendEvent(e);

    this._showSourcesPopup = !this._showSourcesPopup;
    this._showAgentsPopup = false;
  }

  _toggleAgentsDropdown(e) {
    sendEvent(e);

    this._showAgentsPopup = !this._showAgentsPopup;
    this._showSourcesPopup = false;
  }

  _submitSearch(e) {
    this._searchQuery = e.detail;

    this._performSearch();

    const editableElement: any =
      this.shadowRoot?.querySelector('.search__input');
    if (editableElement != null) {
      editableElement.clear();
      editableElement.focus();
    }
  }

  _searchInputChanged(e) {
    this._searchQuery = e.detail;
  }

  _renderSearchInputElement() {
    return html`
      <adele-search-input
        class="search__input"
        @search=${this._submitSearch}
        @model-value-changed=${this._searchInputChanged}
      ></adele-search-input>
    `;
  }

  _renderSearchIcons() {
    return html`
      <div class="search__actions">
        <div
          nn-analytics-id="sources-button"
          nn-analytics-description="${!this._showSourcesPopup
            ? 'Open'
            : 'Close'} sources popup"
          @click=${this._toggleSourceDropdown}
          @keyup=${this._toggleSourceDropdown}
          style="display: flex; height: 32px; padding: 0px 13px; gap: 12px; align-items: center; flex-wrap: nowrap; background-color: rgba(255, 255, 255, 0.1); border-radius: 4px; border: 2px solid transparent; position: relative; box-sizing: content-box; margin: 0px 10px; cursor: pointer;"
          class="main-container"
          id="sourcesButton"
        >
          <span
            style="height:16px;flex-shrink:0;flex-basis:auto;font-family:Nunito Sans, var(--default-font-family);font-size:12px;font-weight:700;line-height:16px;color:#ffffff;letter-spacing:-0.24px;position:relative;text-align:left;white-space:nowrap"
            >SOURCES</span
          >
          <div
            style="display: none; width: 35px; padding: 4px 10px; gap: 8px; justify-content: center; align-items: center; flex-shrink: 0; flex-wrap: nowrap; border-radius: 99px; border: 1px solid rgb(64, 255, 215); position: relative; z-index: 1;"
            id="sourcesValue"
          >
            <span
              style="height:14px;flex-shrink:0;flex-basis:auto;font-family:Nunito Sans, var(--default-font-family);font-size:12px;font-weight:600;line-height:14px;color:#ffffff;position:relative;text-align:left;white-space:nowrap;z-index:2"
              >+1</span
            >
          </div>
        </div>
        <div
          nn-analytics-id="agents-button"
          nn-analytics-description="${!this._showAgentsPopup
            ? 'Open'
            : 'Close'} agents popup"
          @click=${this._toggleAgentsDropdown}
          @keyup=${this._toggleAgentsDropdown}
          style="display: flex; height: 32px; padding: 0px 13px; gap: 12px; align-items: center; flex-wrap: nowrap; background-color: rgba(255, 255, 255, 0.1); border-radius: 4px; border: 2px solid transparent; position: relative; box-sizing: content-box; margin: 0px 10px; cursor: pointer;"
          class="main-container"
          id="agentsButton"
        >
          <span
            style="height:16px;flex-shrink:0;flex-basis:auto;font-family:Nunito Sans, var(--default-font-family);font-size:12px;font-weight:700;line-height:16px;color:#ffffff;letter-spacing:-0.24px;position:relative;text-align:left;white-space:nowrap"
            >AGENTS</span
          >
          <div
            style="display: none; width: 35px; padding: 4px 10px; gap: 8px; justify-content: center; align-items: center; flex-shrink: 0; flex-wrap: nowrap; border-radius: 99px; border: 1px solid rgb(64, 255, 215); position: relative; z-index: 1;"
            id="agentsValue"
          >
            <span
              style="height:14px;flex-shrink:0;flex-basis:auto;font-family:Nunito Sans, var(--default-font-family);font-size:12px;font-weight:600;line-height:14px;color:#ffffff;position:relative;text-align:left;white-space:nowrap;z-index:2"
              >+1</span
            >
          </div>
        </div>

        <button class="search__send">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="38"
            viewBox="0 0 39 38"
            fill="none"
          >
            <rect
              x="0.636719"
              y="0.211914"
              width="37.9657"
              height="37.4233"
              rx="10.0885"
              fill="#ffffff10"
            ></rect>
            <path
              d="M18.449 18.9245L13.9149 18.9245M28.467 18.4099C28.5634 18.4568 28.6447 18.53 28.7016 18.621C28.7584 18.7119 28.7885 18.8171 28.7884 18.9244C28.7883 19.0316 28.7581 19.1367 28.7011 19.2276C28.6441 19.3185 28.5627 19.3915 28.4662 19.4384L11.196 27.7553C11.0927 27.805 10.9769 27.8225 10.8636 27.8056C10.7502 27.7887 10.6446 27.7381 10.5604 27.6603C10.4761 27.5826 10.4172 27.4814 10.3912 27.3698C10.3652 27.2582 10.3734 27.1413 10.4146 27.0344L13.4547 19.1293C13.5054 18.9975 13.5054 18.8515 13.4547 18.7197L10.4154 10.8138C10.3745 10.707 10.3666 10.5904 10.3927 10.4791C10.4187 10.3677 10.4776 10.2668 10.5617 10.1892C10.6457 10.1117 10.7511 10.0612 10.8641 10.0441C10.9772 10.0271 11.0928 10.0443 11.196 10.0937L28.467 18.4099Z"
              stroke="white"
              stroke-width="3.39973"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </div>
    `;
  }

  _renderSearchInput() {
    return html`<form
      class="search"
      nn-analytics-id="overlay-search-form"
      nn-analytics-description="overlay-search-form"
      @submit=${e => this._handleSubmit(e)}
    >
      <label class="search__label" for="search"></label>
      <div class="search__input-wrap">
        ${renderMagnifierIcon()}${this._renderSearchInputElement()}
        ${this._renderSearchIcons()} ${renderSendSearchActions()}
      </div>
    </form>`;
  }

  _setFocus() {
    const inputField = this.shadowRoot?.getElementById(
      'search'
    ) as HTMLInputElement;

    inputField?.focus();
  }

  _setSelectedSource({ detail: { data } }) {
    this._selectedSource = data;
  }

  _setSelectedAgent({ detail: { data } }) {
    this._selectedAgent = data;
  }

  renderSourcesAndAgentsPopup() {
    if (!this._showSourcesPopup && !this._showAgentsPopup) return nothing;

    return html`
      <adele-source-agents
        style="--source-agents-position: relative; --source-agents-absolute-right: 0; --source-agents-absolute-bottom: 0"
        .selectedSource=${this._selectedSource}
        .selectedAgent=${this._selectedAgent}
        .currentAccount=${this._currentAccount}
        .display=${this._showSourcesPopup ? 'sources' : 'agents'}
        @source-changed=${this._setSelectedSource}
        @agent-changed=${this._setSelectedAgent}
      ></adele-source-agents>
    `;
  }

  render() {
    return html`<div class="wrapper">
      <div class="content">
        ${this.renderSourcesAndAgentsPopup()}
        <div class="search-wrapper">${this._renderSearchInput()}</div>
      </div>
      ${this._setFocus()}
    </div>`;
  }
}

export { AdeleNewThreadOverlay };
