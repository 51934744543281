enum Page {
  COLLECTIONS = 'COLLECTIONS_PAGE',
  FREQUENT_TOPIC = 'FREQUENT_TOPIC',
  HOME = 'HOME_PAGE',
  MANAGE = 'MANAGE_PAGE',
  NEW_THREAD = 'NEW_THREAD_OVERLAY',
  THREADS = 'THREADS_PAGE',
}

export { Page };
