import { html, nothing } from 'lit';
import { property } from 'lit/decorators';
import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-spinner';

import { NNBase } from '@mch/nn-web-viz/dist/packages/base/Base';

// Components
import { Notification } from '@vaadin/notification/vaadin-notification';
import './components/login-form/adele-login-form';
import './adele-shell';

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
} from '@azure/msal-browser';
import {
  AzureConfiguration,
  azureConfigurations,
  AzureScope,
} from './constants';

import {
  getClaimsAccountsFromServer,
  getDefaultAccountFromClaims,
  setDataToStore,
} from './modules/claims';

// Store
import { connect, store } from './state/store';
import { setUser } from './state/slices/user';
import { setaccessToken } from './state/slices/token';
import { setAccounts } from './state/slices/appConfig';

class AuthShell extends connect(store)(NNBase) {
  @property({ type: Object }) user = null;

  @property({ type: String }) _authToken: String | null = null;

  @property({ type: Object }) _claimsData = null;

  @property({ type: Boolean }) _loggingIn: boolean = false;

  @property({ type: Object }) _authConfig: AzureConfiguration =
    azureConfigurations.config['90north'];

  @property({ type: Object }) _authScope: AzureScope =
    azureConfigurations.scope['90north'];

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  constructor() {
    super();

    this._catchRedirectResponse();
  }

  async _catchRedirectResponse(
    instance: PublicClientApplication | null = null
  ) {
    this._loggingIn = true;

    const msalInstance =
      instance == null ? await this._getMsAdClient() : instance;

    msalInstance
      .handleRedirectPromise()
      .then((result: AuthenticationResult | null) => {
        if (result == null) {
          this._acquireTokenSilently();
        } else {
          this._saveLoginInformation(result);
        }
      });
  }

  async _acquireTokenSilently(instance: PublicClientApplication | null = null) {
    const shouldStop = instance == null;
    this._loggingIn = true;
    const msalInstance =
      instance == null ? await this._getMsAdClient() : instance;

    try {
      const result = await msalInstance.acquireTokenSilent(this._authScope);

      this._saveLoginInformation(result);

      return result;
    } catch (error) {
      if (shouldStop) {
        this._loggingIn = false;
      }

      throw new Error('Error acquiring token silently');
    }
  }

  get isLoggedIn() {
    return !!this._authToken && !!this._claimsData;
  }

  async _getMsAdClient() {
    const msalInstance = new PublicClientApplication(this._authConfig);
    await msalInstance.initialize();

    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.enableAccountStorageEvents();
    msalInstance.addEventCallback((event: any) => {
      if (!event) return;

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        const account = (event.payload as AuthenticationResult)?.account; // Cast to AuthenticationResult
        if (account) {
          msalInstance.setActiveAccount(account);
        }
      }
    });

    return msalInstance;
  }

  async _fetchTokens() {
    const resultClaimsAccounts = await getClaimsAccountsFromServer();

    if (resultClaimsAccounts.length === 0) {
      store.dispatch(setAccounts([]));

      this._claimsData = null;

      Notification.show(
        'No account was assigned to this record, please contact your administrator.',
        {
          position: 'bottom-end',
          duration: 0,
          theme: 'error',
        }
      );
    } else {
      const currentAccount = await getDefaultAccountFromClaims(
        resultClaimsAccounts
      );

      if (currentAccount != null) {
        setDataToStore(currentAccount);

        this._claimsData = currentAccount;
      }
    }
  }

  async _logoutWithMsAd() {
    const instance = await this._getMsAdClient();

    instance.logoutPopup().then(() => {
      this._authToken = null;
      this.user = null;
    });
  }

  async _saveLoginInformation(result) {
    if (!result) return;

    const userData = JSON.parse(atob(result?.accessToken.split('.')[1]));

    store.dispatch(
      setUser({
        nickname: `${userData?.given_name.replace(
          /([a-z])([A-Z])/g,
          '$1 $2'
        )} ${userData?.family_name}`,
        picture: null,
      })
    );
    store.dispatch(setaccessToken(result?.accessToken));

    this._authToken = result?.accessToken;

    await this._fetchTokens();

    this._loggingIn = false;
  }

  async _loginWithMsAd() {
    this._loggingIn = true;

    const instance = await this._getMsAdClient();

    let result: AuthenticationResult | null = null;

    try {
      result = await this._acquireTokenSilently(instance);
    } catch (error) {
      this._catchRedirectResponse(instance);
      instance.loginRedirect(this._authScope);
    }

    this._saveLoginInformation(result);
  }

  _login({ detail: { email } }) {
    const domain = email.split('@')[1]?.toLowerCase();
    const hasDsiCom = domain === 'dsi.com' || domain === 'daichiisankyo.com';

    if (hasDsiCom) {
      this._authConfig = azureConfigurations.config.dsi;
      this._authScope = azureConfigurations.scope.dsi;
    }

    this._loginWithMsAd();
  }

  render() {
    if (this._loggingIn) {
      return html`<nn-spinner theme="book"></nn-spinner>`;
    }

    if (this._authToken == null) {
      return html` <div
          class="container"
          style="display:flex; height: 100%; width: 100%; justify-content: center; align-items: center;"
        >
          <adele-login-form @login=${this._login}></adele-login-form>
        </div>
        >`;
    }

    if (this._authToken != null) {
      return html`
        <adele-shell
          @login=${this._loginWithMsAd}
          @logout=${this._logoutWithMsAd}
          @refresh-tokens=${this._fetchTokens}
        >
        </adele-shell>
      `;
    }

    return nothing;
  }
}

export { AuthShell };
