import { AnalyticsEvent } from './types/analytics-event';

let events: AnalyticsEvent[] = [];

const _incrementEvent = (index: number): void => {
  events[index] = {
    ...events[index],
    value: (events[index].value || 1) + 1,
  };
};

const _findIndex = (newEvent: AnalyticsEvent): number =>
  events.findIndex(
    event =>
      event.id === newEvent.id &&
      event.type === newEvent.type &&
      event.description === newEvent.description
  );

const addEvent = (event: AnalyticsEvent): void => {
  const index = _findIndex(event);

  if (index !== -1) {
    _incrementEvent(index);
  } else {
    events = [...events, { ...event, value: 1 }];
  }
};

const getEvents = (): AnalyticsEvent[] => events;

const resetEventTracker = () => {
  events = [];
};

const sendEvent = event => {
  const id =
    event.currentTarget.getAttribute('nn-analytics-id') || 'ID not found';
  const description =
    event.currentTarget.getAttribute('nn-analytics-description') ||
    'No description';
  const analyticsEvent: AnalyticsEvent = {
    type: event?.type || 'Unknown type',
    id,
    description,
  };

  addEvent(analyticsEvent);
};

export { addEvent, getEvents, resetEventTracker, sendEvent };
