import { MONDAY_ENDPOINT } from '../../constants/endpoints';
import { axios, Axios } from '@bundled-es-modules/axios';

class MondayClient {
  private client: Axios | null = null;
  public initComplete: Promise<void>;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    // TODO: Move to env
    const token =
      'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMyMTc5ODQ0NCwiYWFpIjoxMSwidWlkIjozMDcxNzM3NywiaWFkIjoiMjAyNC0wMi0xNVQyMTo1MTowMi44MTJaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTAwODY2MDcsInJnbiI6InVzZTEifQ.Wsk0figlfnz6ic7zS5ocdXblD7FvyQlUHXzWKzmtEVs';

    this.client = axios.create({
      baseURL: MONDAY_ENDPOINT,
      headers: {
        Authorization: token,
      },
    });
  }

  async load(qry, variables) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Monday client not initialized');
    }

    const response = await this.client.post('/v2', {
      query: qry,
      variables,
    });

    if (response.data.errors && response.data.errors.length > 0) {
      throw new Error(response?.data?.errors[0].message);
    }

    return response.data.data;
  }
}

const mondayClient = new MondayClient();

export { mondayClient, MondayClient };
