function getOS() {
  const userAgent = navigator.userAgent;
  let os = 'Unknown OS';

  if (/Windows NT 10.0/i.test(userAgent)) {
    os = 'Windows 10';
  } else if (/Windows NT 6.2/i.test(userAgent)) {
    os = 'Windows 8';
  } else if (/Windows NT 6.1/i.test(userAgent)) {
    os = 'Windows 7';
  } else if (/Mac OS X/i.test(userAgent)) {
    os = 'Mac OS X';
  } else if (/Android/i.test(userAgent)) {
    os = 'Android';
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    os = 'iOS';
  } else if (/Linux/i.test(userAgent)) {
    os = 'Linux';
  }

  return os;
}

export { getOS };
